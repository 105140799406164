/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Key } from "../key";
import { ValueData } from "../value_data";

export const protobufPackage = "tanagra.dataschema";

/** Data for an entity group criteria is a list of selected values. */
export interface EntityGroup {
  selected: EntityGroup_Selection[];
  /**
   * Data for an additional categorical or numeric value associated with the
   * selection (e.g. a measurement value).
   */
  valueData: ValueData | undefined;
}

export interface EntityGroup_Selection {
  /**
   * The key of the selected value, which references a related entity (e.g.
   * condition for a condition_occurrence).
   */
  key:
    | Key
    | undefined;
  /**
   * The visible name for the selection. This is stored to avoid extra lookups
   * when rendering.
   */
  name: string;
  /**
   * The entity group is stored to differentiate between them when multiple
   * are configured within a single criteria.
   */
  entityGroup: string;
}

function createBaseEntityGroup(): EntityGroup {
  return { selected: [], valueData: undefined };
}

export const EntityGroup = {
  encode(message: EntityGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.selected) {
      EntityGroup_Selection.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.valueData !== undefined) {
      ValueData.encode(message.valueData, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntityGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntityGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selected.push(EntityGroup_Selection.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.valueData = ValueData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntityGroup {
    return {
      selected: globalThis.Array.isArray(object?.selected)
        ? object.selected.map((e: any) => EntityGroup_Selection.fromJSON(e))
        : [],
      valueData: isSet(object.valueData) ? ValueData.fromJSON(object.valueData) : undefined,
    };
  },

  toJSON(message: EntityGroup): unknown {
    const obj: any = {};
    if (message.selected?.length) {
      obj.selected = message.selected.map((e) => EntityGroup_Selection.toJSON(e));
    }
    if (message.valueData !== undefined) {
      obj.valueData = ValueData.toJSON(message.valueData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntityGroup>, I>>(base?: I): EntityGroup {
    return EntityGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntityGroup>, I>>(object: I): EntityGroup {
    const message = createBaseEntityGroup();
    message.selected = object.selected?.map((e) => EntityGroup_Selection.fromPartial(e)) || [];
    message.valueData = (object.valueData !== undefined && object.valueData !== null)
      ? ValueData.fromPartial(object.valueData)
      : undefined;
    return message;
  },
};

function createBaseEntityGroup_Selection(): EntityGroup_Selection {
  return { key: undefined, name: "", entityGroup: "" };
}

export const EntityGroup_Selection = {
  encode(message: EntityGroup_Selection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.entityGroup !== "") {
      writer.uint32(26).string(message.entityGroup);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntityGroup_Selection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntityGroup_Selection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.entityGroup = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntityGroup_Selection {
    return {
      key: isSet(object.key) ? Key.fromJSON(object.key) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      entityGroup: isSet(object.entityGroup) ? globalThis.String(object.entityGroup) : "",
    };
  },

  toJSON(message: EntityGroup_Selection): unknown {
    const obj: any = {};
    if (message.key !== undefined) {
      obj.key = Key.toJSON(message.key);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.entityGroup !== "") {
      obj.entityGroup = message.entityGroup;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntityGroup_Selection>, I>>(base?: I): EntityGroup_Selection {
    return EntityGroup_Selection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntityGroup_Selection>, I>>(object: I): EntityGroup_Selection {
    const message = createBaseEntityGroup_Selection();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.name = object.name ?? "";
    message.entityGroup = object.entityGroup ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
