/* tslint:disable */
/* eslint-disable */
/**
 * Tanagra Service API
 * github.com/DataBiosphere/tanagra
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HintQueryRelatedEntity,
    HintQueryRelatedEntityFromJSON,
    HintQueryRelatedEntityFromJSONTyped,
    HintQueryRelatedEntityToJSON,
} from './';

/**
 * Get display hints
 * @export
 * @interface HintQuery
 */
export interface HintQuery {
    /**
     * 
     * @type {HintQueryRelatedEntity}
     * @memberof HintQuery
     */
    relatedEntity?: HintQueryRelatedEntity;
}

export function HintQueryFromJSON(json: any): HintQuery {
    return HintQueryFromJSONTyped(json, false);
}

export function HintQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HintQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedEntity': !exists(json, 'relatedEntity') ? undefined : HintQueryRelatedEntityFromJSON(json['relatedEntity']),
    };
}

export function HintQueryToJSON(value?: HintQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedEntity': HintQueryRelatedEntityToJSON(value.relatedEntity),
    };
}


